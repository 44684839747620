export interface NavItem {
  code: string;
  displayName: string;
  iconName: string;
  path: string;
  hasRightMenu?: boolean;
  hasFilterCount?: boolean;
}

const settingsNavItem: NavItem = {
  displayName: 'Settings',
  iconName: 'settings',
  code: 'settings',
  path: '/hospital-officer',
  hasRightMenu: false,
};

export const onboardingNavItems: NavItem[] = [
  {
    displayName: '',
    iconName: 'locumsnest',
    code: 'nest',
    path: null,
    hasRightMenu: false,
    hasFilterCount: false,
  },
  settingsNavItem,
];

export const locumsnestNavItems: NavItem[] = [
  {
    displayName: 'Match',
    iconName: 'locumsnest',
    code: 'nest',
    path: null,
    hasRightMenu: true,
    hasFilterCount: false,
  },
  {
    displayName: 'Filters',
    iconName: 'filters',
    code: 'filters',
    path: null,
    hasRightMenu: true,
    hasFilterCount: true,
  },
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    code: 'dashboard',
    path: '/dashboard',
    hasRightMenu: false,
  },
  {
    displayName: 'Publish a shift',
    iconName: 'publish',
    code: 'publish-job',
    path: '/job-listings/new',
    hasRightMenu: false,
  },
  {
    displayName: 'My shifts',
    iconName: 'shifts',
    code: 'shifts',
    path: '/job-listings',
    hasRightMenu: false,
  },
  {
    displayName: 'Timesheets',
    iconName: 'timesheets',
    code: 'timesheets',
    path: '/time-sheets',
    hasRightMenu: false,
  },
  settingsNavItem,
];

export const intelligenceNavItems: NavItem[] = [
  {
    displayName: 'Intelligence',
    iconName: 'intelligence',
    code: 'nest',
    path: null,
    hasRightMenu: true,
    hasFilterCount: false,
  },
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    code: 'dashboard',
    path: '/intelligence/dashboard',
    hasRightMenu: false,
  },
  {
    displayName: 'Concierge',
    iconName: 'concierge',
    code: 'concierge',
    path: '/intelligence/concierge',
    hasRightMenu: false,
  },
  {
    displayName: 'Export Data',
    iconName: 'export',
    code: 'export',
    path: '/intelligence/export-data',
    hasRightMenu: false,
  },
  settingsNavItem,
];

export const passportNavItems: NavItem[] = [
  {
    displayName: 'Passport Plus',
    iconName: 'passport',
    code: 'nest',
    path: null,
    hasRightMenu: true,
    hasFilterCount: false,
  },
  {
    displayName: 'Filters',
    iconName: 'filters',
    code: 'filters',
    path: null,
    hasRightMenu: true,
    hasFilterCount: true,
  },
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    code: 'dashboard',
    path: '/passport/dashboard',
    hasRightMenu: false,
  },
  {
    displayName: 'My Staff Bank',
    iconName: 'staffbank',
    code: 'staffbank',
    path: '/passport/my-staff-bank',
    hasRightMenu: false,
  },
  {
    displayName: 'Recruitment pipeline',
    iconName: 'recruitment',
    code: 'recruitment',
    path: '/passport/staff-bank-requests',
    hasRightMenu: false,
  },
  {
    displayName: 'Direct Engagement Agency Workers',
    iconName: 'de-workers',
    code: 'de_agency_workers',
    path: '/passport/de-workers',
    hasRightMenu: false,
  },
  {
    displayName: 'Import Members',
    iconName: 'import',
    code: 'import',
    path: '/passport/staff-bank-import',
    hasRightMenu: false,
  },
  settingsNavItem,
];

export const communityNavItems: NavItem[] = [
  {
    displayName: 'Community',
    iconName: 'community',
    code: 'nest',
    path: null,
    hasRightMenu: true,
    hasFilterCount: false,
  },
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    code: 'dashboard',
    path: '/community/dashboard',
    hasRightMenu: false,
  },
  settingsNavItem,
];

export const linkNavItems: NavItem[] = [
  {
    displayName: 'Link',
    iconName: 'link',
    code: 'nest',
    path: null,
    hasRightMenu: true,
    hasFilterCount: false,
  },
  {
    displayName: 'Filters',
    iconName: 'filters',
    code: 'filters',
    path: null,
    hasRightMenu: true,
    hasFilterCount: true,
  },
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    code: 'dashboard',
    path: '/link/dashboard',
    hasRightMenu: false,
  },
  {
    displayName: 'Rostering',
    iconName: 'rostering',
    code: 'rostering',
    path: '/link/rostered-shifts',
    hasRightMenu: false,
  },
  settingsNavItem,
];

export const rotaNavItems: NavItem[] = [
  {
    displayName: 'Rota',
    iconName: 'rota',
    code: 'nest',
    path: null,
    hasRightMenu: true,
    hasFilterCount: false,
  },
  settingsNavItem,
];

export const controlNavItems: NavItem[] = [
  {
    displayName: 'Control',
    iconName: 'control',
    code: 'nest',
    path: null,
    hasRightMenu: true,
    hasFilterCount: false,
  },
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    code: 'dashboard',
    path: 'control/dashboard',
    hasRightMenu: false,
  },
  settingsNavItem,
];

export const agencyNavItems: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    code: 'dashboard',
    path: '/agency/dashboard',
    hasRightMenu: false,
  },
  {
    displayName: 'My bids',
    iconName: 'shifts',
    code: 'bids',
    path: '/agency/listing',
    hasRightMenu: false,
  },
  {
    displayName: 'Invoicing',
    iconName: 'timesheets',
    code: 'invoicing',
    path: '/agency/invoicing',
    hasRightMenu: false,
  },
];

const controlShiftsNavItem: NavItem = {
  displayName: 'Shifts',
  iconName: 'shifts',
  code: 'shifts',
  path: 'control/shifts',
  hasRightMenu: false,
};

const controlTimesheetsNavItem: NavItem = {
  displayName: 'Timesheets',
  iconName: 'timesheets',
  code: 'timesheets',
  path: 'control/timesheets',
  hasRightMenu: false,
};

const controlStaffNavItem: NavItem = {
  displayName: 'Staff',
  iconName: 'staffbank',
  code: 'staff',
  path: 'control/staff',
  hasRightMenu: false,
};

const controlRatesNavItem: NavItem = {
  displayName: 'Rates',
  iconName: 'rates',
  code: 'rates',
  path: 'control/rates',
  hasRightMenu: false,
};

const controlAgencyNavItem: NavItem = {
  displayName: 'Agency',
  iconName: 'agency',
  code: 'agency',
  path: 'control/agency',
  hasRightMenu: false,
};

const extraControlNavItems: NavItem[] = [
  controlShiftsNavItem,
  controlTimesheetsNavItem,
  controlRatesNavItem,
  controlStaffNavItem,
  controlAgencyNavItem,
];

const controlNavMap = {
  1: controlShiftsNavItem,
  2: controlTimesheetsNavItem,
  3: controlRatesNavItem,
  4: controlStaffNavItem,
  5: controlAgencyNavItem,
};

export const getNavItemsByIds = (product: string, ids: number[]) => {
  const navItems: NavItem[] = [];
  if (product === 'control') {
    ids.forEach((id) => {
      navItems.push(controlNavMap[id]);
    });
  }
  return navItems;
};

export const getControlNavItems = () =>
  Object.entries(controlNavMap).map(([id, navItem]) => ({
    id: Number(id),
    displayName: navItem.displayName,
  }));

export const getNavItemDisplayNameByCode = (product: string, code: string): string => {
  let displayName = '';
  if (product === 'control') {
    extraControlNavItems.map((extraControlNavItem) => {
      if (extraControlNavItem.code === code) {
        displayName = extraControlNavItem.displayName;
      }
    });
  }
  return displayName;
};
